// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

// jQuery (as a read only property so browser extensions can't clobber it)
const jquery = require("jquery")
const descriptor = { value: jquery, writable: false, configurable: false }
Object.defineProperties(window, { $: descriptor, jQuery: descriptor })

// App libraries
require("bootstrap")
require("jquery.nicescroll")
require("jquery_upload_preview/assets/js/jquery.uploadPreview")

require("select2")
global.toastr = require("izitoast")

require("jquery-mask-plugin")

// Stisla
require("vendors/stisla/mask")
require("vendors/stisla/stisla")
require("vendors/stisla/scripts")
require("vendors/stisla/custom")

require("packs/custom")
require("@nathanvda/cocoon")

// Application
require("app").start()


var SPMaskBehavior = function (val) {
  return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
},
  spOptions = {
    onKeyPress: function (val, e, field, options) {
      field.mask(SPMaskBehavior.apply({}, arguments), options);
    }
  };


$(document).on('turbolinks:load', function () {

  $(".select_custom").select2();

  $('.cep').mask('00000-000');
  $('.cpf').mask('999.999.999-99');
  $('.date').mask('99/99/9999');
  $('.phone').mask(SPMaskBehavior, spOptions);

  $('#toggle-modal').fireModal({
    title: 'Cadastrar webhook',
    body: $("#modal-webhook")
  });


  $('#modal-birthdates').fireModal({
    title: 'Aniversariantes de hoje',
    body: $("#birthdates")
  });


  $('#update_date').fireModal({
    title: 'Atualizar data da coleta',
    body: $("#form_update_date")
  });



  $('#selectAll').on("click", function () {
    var cbxs = $('.selectable');
    cbxs.prop("checked", !cbxs.prop("checked"));
  });


  var all = 0

  $('input[type=checkbox]').on('change', function () {
    all = $('.selectable:checked').length

    if (all > 0) {
      $(".alterar_status").show()
    } else {
      $(".alterar_status").hide()
    }
  });





  $(".change_status").click(function () {
    var ids = []
    var status = $('.status').val()

    var $boxes = $('.selectable:checked');
    for (let index = 0; index < $boxes.length; index++) {
      const element = $boxes[index];
      ids.push(element.value)

    }

    $.ajax({
      method: "PUT",
      url: "/alterar-status",
      data: { status: status, ids: ids }
    }).done(function (msg) {

    });

  });

  $(".print_collects").on('click', function () {
    var ids = []
    var status = $('.status').val()

    var $boxes = $('.selectable:checked');
    for (let index = 0; index < $boxes.length; index++) {
      const element = $boxes[index];
      ids.push(element.value)

    }

    $.ajax({
      method: "POST",
      url: "/imprimir-coletas",
      data: { ids: ids }
    }).done(function (msg) {
      var win = open('', 'windowName', 'height=600,width=1024');
      win.document.body.innerHTML = msg;

    });


  })

});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

