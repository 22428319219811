$(document).on("turbolinks:load", function() {
    $('.panel-heading .clickable').on("click", function (e) {
        if ($(this).hasClass('panel-collapsed')) {
          // expand the panel
          $(this).parents('.panel').find('.panel-body').slideDown();
          $(this).removeClass('panel-collapsed');
          $(this).find('i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        }
        else {
          // collapse the panel
          $(this).parents('.panel').find('.panel-body').slideUp();
          $(this).addClass('panel-collapsed');
          $(this).find('i').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        }
    });


    
});

