$(document).on("turbolinks:load", function() {
    if($('.moeda').length){
        $('.moeda').maskMoney({
            thousands:'.', 
            decimal:','
        });
    }

    

    $(".file").change(function(){
        var file = $(this).get(0).files[0];
 
        if(file){
            var reader = new FileReader();
 
            reader.onload = function(){
                $(".image-preview").css('background-image', 'url(' + reader.result + ')');
            }
 
            reader.readAsDataURL(file);
        }

    })
        
    
    $("#plan_price").on("change", function(){
        let p = $(this).val()


        $("#reference_price").html((parseFloat(p.replace(',', '.')) / 4.33).toFixed(2))

    });
});


$(document).on("keypress", function(event){
    if (event.which == '13') {
        event.preventDefault();
    }
});
